// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_admin-container__JP-na {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.style_admin-button__rgnX7 {
  margin-bottom: 20px;
}
.style_admin-search__SPyTg {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.style_admin-table__UVnmh .style_action__hdIgt {
  display: flex;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/AdminTab/style.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAMI;EACE,aAAA;EACA,SAAA;AAJN","sourcesContent":[".admin {\n  &-container {\n    align-items: center;\n    background-color: #f5f5f5;\n    display: flex;\n    flex-direction: column;\n  }\n\n  &-button {\n    margin-bottom: 20px;\n  }\n\n  &-search {\n    display: flex;\n    gap: 20px;\n    margin-bottom: 20px;\n  }\n\n  &-table {\n    .action {\n      display: flex;\n      gap: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin-container": `style_admin-container__JP-na`,
	"admin-button": `style_admin-button__rgnX7`,
	"admin-search": `style_admin-search__SPyTg`,
	"admin-table": `style_admin-table__UVnmh`,
	"action": `style_action__hdIgt`
};
export default ___CSS_LOADER_EXPORT___;
