import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, message, Modal, Table } from 'antd'

import keycloak from 'keycloak'
import { deleteVehicle, getAdminPanelData } from '@services/api'

import styles from './style.module.scss'

const getColumns = ({ setShowDeleteVehicle, setVehicleId }) => [
	{
		title: 'Owner Id',
		dataIndex: 'ownerId',
		key: 'ownerId',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Vehicle Id',
		dataIndex: 'vehicleId',
		key: 'vehicleId',
	},
	{
		title: 'Vin',
		dataIndex: 'vin',
		key: 'vin',
	},
	{
		title: 'Action',
		dataIndex: '',
		key: 'action',
		render: (record) => {
			return (
				<div className={styles['action']}>
					<Button
						onClick={() => {
							setShowDeleteVehicle(true)
							setVehicleId(record?.vehicleId || '')
						}}
					>
						Delete Vehicle
					</Button>
				</div>
			)
		},
	},
]

function AdminTab() {
	const [messageApi, contextHolder] = message.useMessage()
	const abortControllerRef = useRef(null)
	const debounceRef = useRef(null)

	const [config, setConfig] = useState({ page: 0, size: 10, total: 0, search: '' })
	const [dataSource, setDataSource] = useState([])
	const [loading, setLoading] = useState(false)
	const [search, setSearch] = useState('')
	const [showDeleteVehicle, setShowDeleteVehicle] = useState(false)
	const [token, setToken] = useState('')
	const [vehicleId, setVehicleId] = useState('')

	const columns = getColumns({
		setShowDeleteVehicle,
		setVehicleId,
	})

	useEffect(() => {
		loadData({ page: config.page, size: config.size, textFilter: config.search })
	}, [config.page, config.size, config.search])

	useEffect(() => {
		debounceRef.current = setTimeout(() => {
			setConfig((prev) => ({ ...prev, search, page: 0 }))
		}, 300)

		return () => clearTimeout(debounceRef.current)
	}, [search])

	useEffect(() => {
		if (keycloak?.token) {
			setToken(keycloak.token)
		}
	}, [keycloak?.token])

	const loadData = async ({ page = 0, size = 10, textFilter = '' }) => {
		setLoading(true)
		try {
			const result = await getAdminPanelData({ page, size, textFilter, ref: abortControllerRef })
			setDataSource(result?.content || [])
			setConfig((prev) => ({
				...prev,
				total: result?.totalElements || 0,
			}))
		} finally {
			setLoading(false)
		}
	}

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(token)
		} catch (error) {
			console.error('Failed to copy text to clipboard:', error)
		}
	}

	const handleDeleteVehicle = async (id) => {
		await deleteVehicle(id)
		loadData({ page: config.page, size: config.size, textFilter: search })
		setVehicleId('')
		setShowDeleteVehicle(false)
	}

	const handleChange = (pagination) => {
		setConfig((prev) => ({
			...prev,
			page: pagination.current - 1,
			size: pagination.pageSize,
		}))
	}

	return (
		<div className={styles['admin-container']}>
			{contextHolder}
			<h2>Admin Panel</h2>

			<Button className={styles['admin-button']} onClick={copyToClipboard}>
				Copy Token
			</Button>

			<div className={styles['admin-search']}>
				<Input
					value={search}
					onChange={(e) => {
						setSearch(e.target.value)
					}}
				/>
			</div>

			<Table
				className={styles['admin-table']}
				columns={columns}
				dataSource={dataSource}
				loading={loading}
				onChange={handleChange}
				pagination={{
					current: config.page + 1,
					pageSize: config.size,
					total: config.total,
				}}
				rowKey="vehicleId"
				scroll={{ x: 'max-content' }}
			/>

			<Modal
				title={'Delete Vehicle'}
				open={showDeleteVehicle}
				centered
				onCancel={() => setShowDeleteVehicle(false)}
				onOk={() => handleDeleteVehicle(vehicleId)}
				okText={'Delete'}
			>
				<p>Are you sure you want to delete the vehicle?</p>
			</Modal>
		</div>
	)
}

export default AdminTab
