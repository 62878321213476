import { useEffect, useState } from 'react'

import { getStatistics } from '@services/api'
import { INTERVAL_TIME } from '@constants/common'

const useEventSource = () => {
	const [id, setId] = useState(null)
	const [data, setData] = useState(null)
	const [actualSoc, setActualSoc] = useState(0)
	const [balance, setBalance] = useState({})
	const [charging, setCharging] = useState(null)
	const [chargingETA, setChargingEta] = useState(null)
	const [chargingUsingDC, setChargingUsingDC] = useState(false)
	const [fullyCharged, setFullyCharged] = useState(null)
	const [pluggedIn, setPluggedIn] = useState(null)
	const [saving, setSaving] = useState({})
	const [targetSoc, setTargetSoc] = useState(0)

	useEffect(() => {
		let intervalId = null

		if (id) {
			const fetchData = async () => {
				const response = await getStatistics(id)
				if (response) {
					setData(response)
				}
			}

			intervalId = setInterval(async () => {
				const response = await getStatistics(id)
				if (response) {
					setData(response)
				}
			}, INTERVAL_TIME)

			fetchData()
		}

		if (id === null) {
			setData(null)
		}

		return () => {
			clearInterval(intervalId)
		}
	}, [id])

	useEffect(() => {
		if (data) {
			if (data.costSavings) setSaving(data.costSavings)
			if (data.co2balance) setBalance(data.co2balance)
			if (data.chargingETA) setChargingEta(data.chargingETA)
			if (data.lastChargingStatus.actualSoc) setActualSoc(data.lastChargingStatus.actualSoc)
			if (data.lastChargingStatus.targetSoc) setTargetSoc(data.lastChargingStatus.targetSoc)

			setCharging(data.lastChargingStatus.charging)
			setFullyCharged(data.lastChargingStatus.fullyCharged)
			setPluggedIn(data.lastChargingStatus.pluggedIn)
			setChargingUsingDC(data.lastChargingStatus.chargingUsingDC)
		}

		if (data === null) {
			setActualSoc(0)
			setBalance({})
			setCharging(null)
			setChargingEta(null)
			setChargingUsingDC(false)
			setFullyCharged(null)
			setPluggedIn(null)
			setSaving({})
			setTargetSoc(0)
		}
	}, [data])

	return {
		id,
		saving,
		balance,
		charging,
		chargingETA,
		fullyCharged,
		pluggedIn,
		actualSoc,
		targetSoc,
		setId,
		chargingUsingDC,
	}
}
export default useEventSource
