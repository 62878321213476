import React from 'react'

export const HPCIcon = ({ fill = 'currentColor', viewBox = '0 0 24 10', className = '' }) => {
	return (
		<svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				fill={fill}
				d="M6.60433 0.636V9H5.51233V5.208H1.25233V9H0.160328V0.636H1.25233V4.308H5.51233V0.636H6.60433ZM14.0609 3.084C14.0609 3.78 13.8209 4.36 13.3409 4.824C12.8689 5.28 12.1449 5.508 11.1689 5.508H9.56092V9H8.46892V0.636H11.1689C12.1129 0.636 12.8289 0.864 13.3169 1.32C13.8129 1.776 14.0609 2.364 14.0609 3.084ZM11.1689 4.608C11.7769 4.608 12.2249 4.476 12.5129 4.212C12.8009 3.948 12.9449 3.572 12.9449 3.084C12.9449 2.052 12.3529 1.536 11.1689 1.536H9.56092V4.608H11.1689ZM15.0101 4.812C15.0101 3.996 15.1941 3.264 15.5621 2.616C15.9301 1.96 16.4301 1.448 17.0621 1.08C17.7021 0.712 18.4101 0.528 19.1861 0.528C20.0981 0.528 20.8941 0.748 21.5741 1.188C22.2541 1.628 22.7501 2.252 23.0621 3.06H21.7541C21.5221 2.556 21.1861 2.168 20.7461 1.896C20.3141 1.624 19.7941 1.488 19.1861 1.488C18.6021 1.488 18.0781 1.624 17.6141 1.896C17.1501 2.168 16.7861 2.556 16.5221 3.06C16.2581 3.556 16.1261 4.14 16.1261 4.812C16.1261 5.476 16.2581 6.06 16.5221 6.564C16.7861 7.06 17.1501 7.444 17.6141 7.716C18.0781 7.988 18.6021 8.124 19.1861 8.124C19.7941 8.124 20.3141 7.992 20.7461 7.728C21.1861 7.456 21.5221 7.068 21.7541 6.564H23.0621C22.7501 7.364 22.2541 7.984 21.5741 8.424C20.8941 8.856 20.0981 9.072 19.1861 9.072C18.4101 9.072 17.7021 8.892 17.0621 8.532C16.4301 8.164 15.9301 7.656 15.5621 7.008C15.1941 6.36 15.0101 5.628 15.0101 4.812Z"
			/>
		</svg>
	)
}
