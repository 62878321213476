import axios from 'axios'
import { message } from 'antd'

import keycloak from 'keycloak'
// const token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3NzM0dWdVcHFObFJkNGs3cGF5Slp3V2Q2VG04dTl0amItWnd3WjBPNnlnIn0.eyJleHAiOjE2ODc1MDkyNjIsImlhdCI6MTY4NzQ5ODQ2MiwianRpIjoiYjNiZTAwZGQtNzdhNy00N2I3LThkMTAtZTJkOTliYzA5OWRhIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwL2F1dGgvcmVhbG1zL2llMnMiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiYjIyZmU5NzgtNmVmMC00MTRhLWFhMTUtOWQ5NjE5MTZjZjZhIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiaWUyc19iYWNrZW5kIiwic2Vzc2lvbl9zdGF0ZSI6ImEzYzgxN2RlLTEwZDItNDhhNi1hZTYwLTAxZjg0YTZkYzI0OCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cDovL2xvY2FsaG9zdDo4ODg4LyoiLCJodHRwOi8vbG9jYWxob3N0Ojg4ODgiLCJodHRwOi8vbG9jYWxob3N0OjMwMDAvKiIsIioiLCJodHRwOi8vbG9jYWxob3N0OjMwMDAiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWllMnMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJpZTJzX2JhY2tlbmQiOnsicm9sZXMiOlsidXNlciJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwic2lkIjoiYTNjODE3ZGUtMTBkMi00OGE2LWFlNjAtMDFmODRhNmRjMjQ4IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJyb2xlcyI6WyJ1c2VyIl0sInByZWZlcnJlZF91c2VybmFtZSI6InVzZXIifQ.otePQPZyqp0IOOSENL0OmbdB9CO0krOfb1o9lIjZlrVwTtHdHfiHFZ8p7wCwhpYi82s0tJhV23a9r8_iTOqUc5ye9kCR5oKOw4nWUA9QIE8gKcAalv8IBVrXHRsrdz95OHfOYFSng1um-hw2zNoIp8wnZKlVFRZNnrCHY8ihAIrS4ns1O5LrkpVrCY2NjJQjjexsORyaFTNYb3qlKYtWsS4ye5z8vNmVsXx6C_q8ZKkZGlXc9YodZxSVIUaq48ppRDe9E-gYMNlEyUjFm2-ZZuGuKq0C4sOgx-nIjV1xrZ4l8e98Y7FbNjv36SFCXjbzV57s4Tl_XJPwmnOckXCSKA'
// curl -s -X POST "http://localhost:5000/auth/realms/ie2s/protocol/openid-connect/token" --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'grant_type=password' --data-urlencode "client_id=ie2s_backend" --data-urlencode "client_secret=dlQcz69nL3oyiU7q4DH2k9i8c0e00ijT" --data-urlencode "username=user" --data-urlencode "password=user"| jq -r '.access_token'

const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888/api'
const baseUrlUpdate = process.env.REACT_APP_API_URL_UPDATE

const getToken = async () => {
	try {
		await keycloak.updateToken(60)
	} catch (error) {
		console.error('Failed to refresh the token: ', error)
		keycloak.login()
	}

	return keycloak.token
}

const getHeaders = async (type = '') => {
	const token = await getToken()
	const headers = { Authorization: `Bearer ${token}` }

	if (type === 'file') {
		return { ...headers, 'Content-Type': 'multipart/form-data' }
	}

	if (type === 'bytes') {
		return {
			...headers,
			accept: 'application/octet-stream',
			'Content-Disposition': 'attachment; filename=ie2s_report.xlsx',
			'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		}
	}

	return headers
}

// Bev Control Board
export const getSessions = async () => {
	try {
		const response = await axios.get(`${baseUrl}/v1/session-params?page=1&size=100`, {
			headers: await getHeaders(),
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const putSessions = async (idBev, body) => {
	try {
		const response = await axios.put(`${baseUrl}/v1/session-params/${idBev}`, body, {
			headers: await getHeaders(),
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const deleteSessions = async (idBev) => {
	try {
		const response = await axios.delete(`${baseUrl}/v1/session-params?idsBev=${idBev.join(',')}`, {
			headers: await getHeaders(),
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const uploadSessions = async (file) => {
	try {
		const response = await axios.post(
			`${baseUrl}/v1/session-params/upload`,
			{ file },
			{
				headers: await getHeaders('file'),
			}
		)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

// Intro_day rest controller

export const uploadIntro = async (file) => {
	try {
		const response = await axios.post(
			`${baseUrl}/v1/schedule/upload`,
			{ file },
			{
				headers: await getHeaders('file'),
			}
		)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const optimisationIntro = async () => {
	try {
		const response = await axios.post(
			`${baseUrl}/v1/schedule/optimize`,
			{},
			{
				headers: await getHeaders(),
			}
		)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const modeIntro = async (item) => {
	try {
		const response = await axios.post(
			`${baseUrl}/v1/schedule/mode?mode=${item}`,
			{},
			{
				headers: await getHeaders(),
			}
		)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getScheduleIntro = async () => {
	try {
		const response = await axios.get(`${baseUrl}/v1/schedule`, {
			headers: await getHeaders(),
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getScheduleExportIntro = async (item) => {
	try {
		const response = await axios.get(`${baseUrl}/v1/schedule/export?from=${item[0]}&to=${item[1]}`, {
			headers: await getHeaders('bytes'),
			responseType: 'arraybuffer',
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getHints = async () => {
	try {
		const response = await axios.get(`${baseUrl}/v1/system-hints/schedule`, {
			headers: await getHeaders(),
		})
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const logOut = async () => {
	try {
		const response = await axios.post(
			`${baseUrl}/v1/auth/logout`,
			{},
			{
				headers: await getHeaders(),
			}
		)
		return response.data
	} catch (error) {
		console.error(error)
		return null
	}
}

export const getVCFLink = async () => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/vcf/api/v1/users/self/link`, {
			headers: await getHeaders(),
		})
		return response?.data || ''
	} catch (error) {
		console.error(error)
		return null
	}
}

export const saveToken = async (token) => {
	try {
		await axios.post(
			`${baseUrlUpdate}/vcf/api/v1/users/self/register`,
			{ token },
			{
				headers: await getHeaders(),
			}
		)
	} catch (error) {
		console.error(error)
	}
}

export const deleteENODELink = async () => {
	try {
		await axios.delete(`${baseUrlUpdate}/vcf/api/v1/users/self/unlink`, {
			headers: await getHeaders(),
		})
	} catch (error) {
		console.error(error)
	}
}

export const getVehicleList = async () => {
	try {
		const response = await axios.get(`${baseUrl}/v1/vehicles/search?size=10&page=0`, {
			headers: await getHeaders(),
		})
		return response?.data?.content || []
	} catch (error) {
		console.error(error)
		return []
	}
}

export const deleteUser = async () => {
	try {
		await axios.delete(`${baseUrl}/v1/users`, {
			headers: await getHeaders(),
		})
	} catch (error) {
		console.error(error)
	}
}

export const deleteUserbyId = async (id) => {
	try {
		const response = await axios.delete(`${baseUrl}/v1/users/${id}`, {
			headers: await getHeaders(),
		})
		return response
	} catch (error) {
		console.error(error)
		return error
	}
}

export const updatePassword = async (password) => {
	try {
		await axios.put(
			`${baseUrl}/v1/users/self/reset-password`,
			{ password },
			{
				headers: await getHeaders(),
			}
		)
	} catch (error) {
		console.error(error)
	}
}

export const getVehicleStatus = async (id) => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/reports/api/v1/users/self/vehicles/${id}/status`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const addVehicle = async (data) => {
	try {
		await axios.post(
			`${baseUrlUpdate}/api/v1/vehicles`,
			{
				...data,
				accessProvider: 'VCF',
				scheduleProvider: 'TBW',
				controlMode: 'CONTROLLED',
			},
			{
				headers: await getHeaders(),
			}
		)
	} catch (error) {
		console.error(error)
		message.error(error?.response?.data?.title ?? 'Error adding BEV.', 15)
	}
}

export const virtualGenerate = async (data) => {
	try {
		const response = await axios.post(
			`${baseUrlUpdate}/virtual/api/v1/profiles`,
			{
				...data,
			},
			{
				headers: await getHeaders(),
			}
		)
		message.success('Success Virtual BEVs generated.')
		return response?.data || {}
	} catch (error) {
		message.error(error?.response?.data?.title ?? 'Error while generating Virtual BEVs.')
		return error?.response || ''
	}
}

export const deleteVehicle = async (id) => {
	try {
		const response = await axios.delete(`${baseUrlUpdate}/api/v1/vehicles/${id}`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const updateOperatingMode = async (id, isControlled) => {
	try {
		await axios.put(
			`${baseUrl}/v1/vehicles/${id}/mode?controlMode=${isControlled ? 'CONTROLLED' : 'UNCONTROLLED_SESSION'}`,
			{},
			{
				headers: await getHeaders(),
			}
		)
	} catch (error) {
		console.error(error)
	}
}

export const getReportsDaily = async (id) => {
	try {
		const response = await axios.get(`${baseUrl}/v1/users/self/vehicles/${id}/reports/costs/daily?id_type=VIN`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getReportsMonthly = async (id) => {
	try {
		const response = await axios.get(`${baseUrl}/v1/users/self/vehicles/${id}/reports/costs/monthly?id_type=VIN`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getTestsVehicles = async () => {
	try {
		const response = await axios.get(`${baseUrl}/v1/vehicles/virtual`, {
			headers: await getHeaders(),
		})
		return response?.data?.content || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getWeekendTripProfile = async () => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/virtual/api/v1/sources/weekend-trip-profiles`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getVehicleProfile = async () => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/virtual/api/v1/sources/vehicle-profiles`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getUserProfile = async () => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/virtual/api/v1/sources/user-profiles`, {
			headers: await getHeaders(),
		})
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

//BEV Control Board, TransnetBW, VWKraftwerke

export const getReportsKpiStats = async (from, to, type) => {
	try {
		const response = await axios.get(
			`${baseUrlUpdate}/reports/api/v1/operator/kpi-stats?from=${from}&to=${to}&vehicle_type=${type}`,
			{
				headers: await getHeaders(),
			}
		)
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}
export const getReportsRenewablePower = async (from, to, type, provider) => {
	try {
		const response = await axios.get(
			`${baseUrlUpdate}/reports/api/v1/operator/renewable-energy?from=${from}&to=${to}&vehicle_type=${type}&trader=${provider}`,
			{
				headers: await getHeaders(),
			}
		)
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getReportsCo2Emission = async (from, to, type, provider) => {
	try {
		const response = await axios.get(
			`${baseUrlUpdate}/reports/api/v1/operator/co2-emission?from=${from}&to=${to}&vehicle_type=${type}&trader=${provider}`,
			{
				headers: await getHeaders(),
			}
		)
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getReportRenewableEnergy = async (from, to, type, provider) => {
	try {
		const response = await axios.get(
			`${baseUrlUpdate}/reports/api/v1/operator/renewable-surplus-energy?from=${from}&to=${to}&vehicle_type=${type}&trader=${provider}`,
			{
				headers: await getHeaders(),
			}
		)
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getReportsAvgCosts = async (from, to, type, provider) => {
	try {
		const response = await axios.get(
			`${baseUrlUpdate}/reports/api/v1/operator/avg-costs?from=${from}&to=${to}&vehicle_type=${type}&trader=${provider}`,
			{
				headers: await getHeaders(),
			}
		)
		return response?.data || {}
	} catch (error) {
		console.error(error)
		return {}
	}
}

export const getStatistics = async (id) => {
	try {
		const response = await axios.get(`${baseUrlUpdate}/reports/api/v1/users/self/vehicles/${id}/statistics`, {
			headers: await getHeaders(),
		})

		return response.data
	} catch (error) {
		console.error(error)
	}
}
