import { DatePicker, Select } from 'antd'
import styles from './style.module.scss'
import { Column } from '@ant-design/charts'
import { Card, Statistic, Divider } from 'antd'
import {
	getReportsKpiStats,
	getReportsCo2Emission,
	getReportsRenewablePower,
	getReportsAvgCosts,
	getReportRenewableEnergy,
} from '@services/api'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
function ExtendedStatistic() {
	const [kpiStats, setKpiStats] = useState({})
	const [co2Emission, setCo2Emission] = useState({})
	const [renewablePower, setRenewablePower] = useState({})
	const [avgCosts, setAvgCosts] = useState({})
	const [renewableEnergy, setRenewableEnergy] = useState({})
	const [selectedType, setSelectedType] = useState('ALL') // ALL, REAL, VIRTUAL
	const [trader, setTrader] = useState('COMBINED ') // COMBINED , VWK, TBW
	const [fromDate, setFromDate] = useState(dayjs().format('YYYY-MM-DD'))
	const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'))
	const { RangePicker } = DatePicker
	const onChangeDate = (date, dateString) => {
		setFromDate(dayjs(dateString[0]).format('YYYY-MM-DD'))
		setToDate(dayjs(dateString[1]).format('YYYY-MM-DD'))
	}
	const onChangeSelect = (value) => {
		setSelectedType(value)
	}
	const onChangeTrader = (value) => {
		setTrader(value)
	}
	const getData = async () => {
		const kpiStats = await getReportsKpiStats(fromDate, toDate, selectedType)
		const co2Emission = await getReportsCo2Emission(fromDate, toDate, selectedType, trader)
		const avgCosts = await getReportsAvgCosts(fromDate, toDate, selectedType, trader)
		const renewablePower = await getReportsRenewablePower(fromDate, toDate, selectedType, trader)
		const renewableEnergy = await getReportRenewableEnergy(fromDate, toDate, selectedType, trader)
		setKpiStats(kpiStats)
		setCo2Emission(co2Emission)
		setRenewablePower(renewablePower)
		setRenewableEnergy(renewableEnergy)
		setAvgCosts(avgCosts)
	}

	useEffect(() => {
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate, toDate, selectedType, trader])
	return (
		<Card>
			<div className={styles.extendContainer}>
				<h1>Extended Statistic</h1>
				<div className={styles.dropDownRow}>
					<div className={styles.selectContainer}>
						<span>Select a type of BEV</span>
						<Select
							className={styles.selectType}
							size="large"
							placeholder="Select a type of BEV"
							onChange={onChangeSelect}
							defaultValue="ALL"
							options={[
								{
									value: 'ALL',
									label: 'ALL',
								},
								{
									value: 'REAL',
									label: 'REAL',
								},
								{
									value: 'VIRTUAL',
									label: 'VIRTUAL',
								},
							]}
						/>
					</div>
					<div className={styles.selectContainer}>
						<span>Select Trader</span>
						<Select
							className={styles.selectType}
							size="large"
							placeholder="Select Trader"
							onChange={onChangeTrader}
							defaultValue="COMBINED "
							options={[
								{
									value: 'COMBINED ',
									label: 'ALL',
								},
								{
									value: 'VWK',
									label: 'VWK',
								},
								{
									value: 'TBW',
									label: 'TBW',
								},
							]}
						/>
					</div>
					<RangePicker onChange={onChangeDate} defaultValue={[dayjs(), dayjs()]} format={'YYYY-MM-DD'} />
				</div>
				<div className={styles.diagramRow}>
					<div className={styles.middleDiagram}>
						<h2>Average charging costs</h2>
						<AverageDiagram avg={avgCosts} />
					</div>
					<div className={styles.middleDiagram}>
						<h2>Average CO2 emissions</h2>
						<CODiagram co={co2Emission} />
					</div>
				</div>
				<div className={styles.diagramRow}>
					<div className={styles.middleDiagram}>
						<h2>Renewable surplus charging energy</h2>
						<div className={styles.middleRow}>
							<Card bordered={false}>
								<Statistic
									title="Controlled"
									value={renewableEnergy.controlledEnergy ?? 0}
									precision={2}
									valueStyle={{
										color: '#009900',
									}}
									suffix="%"
								/>
							</Card>
							<Card bordered={false}>
								<Statistic
									title="Uncontrolled"
									value={renewableEnergy.uncontrolledEnergy ?? 0}
									precision={2}
									valueStyle={{
										color: '#b6b1a9',
									}}
									suffix="%"
								/>
							</Card>
						</div>
					</div>
					<Divider type="vertical" />
					<div className={styles.middleDiagram}>
						<h2>Average share of renewable energy</h2>
						<div className={styles.middleRow}>
							<Card bordered={false}>
								<Statistic
									title="Controlled"
									value={renewablePower.controlledAllocation ?? 0}
									precision={2}
									valueStyle={{
										color: '#009900',
									}}
									suffix="%"
								/>
							</Card>
							<Card bordered={false}>
								<Statistic
									title="Uncontrolled"
									value={renewablePower.uncontrolledAllocation ?? 0}
									precision={2}
									valueStyle={{
										color: '#b6b1a9',
									}}
									suffix="%"
								/>
							</Card>
						</div>
					</div>
				</div>
				<Divider />
				<Stats data={kpiStats} />
			</div>
		</Card>
	)
}
export default ExtendedStatistic

const AverageDiagram = ({ avg }) => {
	const { avgCostControlled, avgCostUncontrolled } = avg
	const data = [
		{
			label: 'Controlled',
			value: avgCostControlled ?? 0,
		},
		{
			label: 'Uncontrolled',
			value: avgCostUncontrolled ?? 0,
		},
	]
	const config = {
		data,
		width: window.innerWidth < 768 ? 320 : 400,
		autoFit: false,
		xField: 'label',
		yField: 'value',
		colorField: 'label',
		color: ['#009900', '#b6b1a9'],
		label: {
			content: (item) => {
				return item.value
			},
			// textBaseline: "bottom",
			style: {
				fill: '#000000',
				fontWeight: 'bold',
				fontSize: 18,
			},
		},
		axis: {
			y: {
				title: 'EURct / kWh',
			},
		},
		scale: {
			color: {
				range: ['#008000', '#b6b1a9'],
			},
		},
	}
	return <Column {...config} />
}
const CODiagram = ({ co }) => {
	const { controlledEmission, uncontrolledEmission, germanyElectricityMixEmission } = co
	const data = [
		{
			label: 'Controlled',
			value: controlledEmission ?? 0,
		},
		{
			label: 'Uncontrolled',
			value: uncontrolledEmission ?? 0,
		},
		{
			label: 'German electricity mix',
			value: germanyElectricityMixEmission ?? 0,
		},
	]
	const config = {
		data,
		width: window.innerWidth < 768 ? 320 : 400,
		autoFit: false,
		xField: 'label',
		yField: 'value',
		isPercent: false,
		isStack: false,
		colorField: 'label',
		color: ['#009900', '#b6b1a9', '#00a8ff'],
		label: {
			content: (item) => {
				return item.value
			},
			style: {
				fill: '#000000',
				fontWeight: 'bold',
				fontSize: 18,
			},
		},
		axis: {
			y: {
				title: 'g / kWh',
			},
		},
		scale: {
			color: {
				range: ['#008000', '#b6b1a9', '#00a8ff'],
			},
		},
	}
	return <Column {...config} />
}

const Stats = ({ data }) => {
	const { totalEnergyDemand, avgPlugInTime, chargingCommandStats } = data

	return (
		<>
			<h1>Total energy demand</h1>
			<div className={styles.buttonStatistic}>
				<Card bordered={false}>
					<Statistic
						title="Virtual BEVs"
						value={totalEnergyDemand?.energyDemandVirtualBevsMWh ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="MWh"
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title="Real BEVs"
						value={totalEnergyDemand?.energyDemandRealBevsMWh ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="MWh"
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title="All BEVs"
						value={totalEnergyDemand?.energyDemandAllBevsMWh ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="MWh"
					/>
				</Card>
			</div>
			<Divider />
			<h1>Average plug-in time</h1>
			<div className={styles.buttonStatistic}>
				<Card bordered={false}>
					<Statistic
						title="Virtual BEVs"
						value={avgPlugInTime?.avgPlugInHoursVirtual ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="hours"
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title="Real BEVs"
						value={avgPlugInTime?.avgPlugInHoursReal ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="hours"
					/>
				</Card>
				<Card bordered={false}>
					<Statistic
						title="All BEVs"
						value={avgPlugInTime?.avgPlugInHoursAll ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="hours"
					/>
				</Card>
			</div>
			<Divider />
			<h1>Number of direct charging commands</h1>
			<div className={styles.buttonStatistic}>
				<Card bordered={false}>
					<Statistic
						title=""
						value={chargingCommandStats?.directChargingCommandsCount ?? 0}
						precision={2}
						valueStyle={{
							color: '#009900',
						}}
						suffix="times"
					/>
				</Card>
			</div>
			<Divider />
		</>
	)
}
